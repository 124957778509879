export enum ModuleType {
  Monitoring = 'monitoring',
  Orchestration = 'orchestration',
  Analytics = 'analytics',
  Reporting = 'reporting',
  Admin = 'admin',
  Settings = 'settings',
  Auth = 'auth',
}

export enum PageType {
  // Monitoring
  Processes = 'processes',
  Resources = 'resources',
  Sessions = 'sessions',
  Utilization = 'utilization',
  PastSchedule = 'past-schedule',

  // Orchestration
  Alerts = 'alerts',
  EventLog = 'event-log',
  ProcessConfiguration = 'process-configuration',
  Simulator = 'simulator',

  // Analytics
  DetectedIncidents = 'detect-incidents',
  DiagnosticDashboard = 'diagnostic-dashboard',
  RuntimeAnalysis = 'runtime-analysis',

  // Reporting
  ExecutiveOverview = 'executive-overview',
  ProcessReporting = 'process-reporting',
  MonthlyReports = 'monthly-reports',
  BenchmarkOverview = 'benchmarking',
  ProcessReportingNotFound = 'process-not-found',

  // Settings
  OrganizationSettings = 'organization-settings',

  // Admin
  Organizations = 'organizations',
  Users = 'users',
  Licenses = 'licenses',

  // Auth
  Login = 'login',

  // Others
  DesignSystemOverview = 'design-system-overview',
  NotFound = 'not-found',
}

export enum SubPageType {
  // Utilization
  Licenses = 'licenses',
  Resources = 'resources',
  // Simulator
  Simulator = 'simulator',
  OrchestrationTasks = 'orchestration-tasks',
  SimulatorProcesses = 'processes',
  SimulatorSessions = 'sessions',
  SimulatorEventLog = 'event-log',
  LicenseUtilization = 'license-utilization',
  ResourceUtilization = 'resource-utilization',
  PastSchedule = 'past-schedule',
  // RuntimeAnalysis
  Overview = 'overview',
  ActionableInsights = 'actionable-insights',
  ProcessMap = 'process-map',
  VariantAnalysis = 'variant-analysis',
  CostAnalysis = 'cost-analysis',
  AnalysisNotFound = 'analysis-not-found',
  // Organization Settings
  GeneralSettings = 'general-settings',
  BusinessProcessesSettings = 'business-processes-settings',
  SavingsSettings = 'savings',
  GroupsSettings = 'groups',
  BillingGroupsSettings = 'billing-groups',
  PendingSessions = 'pendings',
  OrchestrationSettings = 'orchestration',
  UsersSettings = 'users',
  ReportingSettings = 'reporting',
  BenchmarkingSettings = 'benchmarking',
  NotificationsSettings = 'notifications',
  ConditionStates = 'condition-states',
}
