import { Injectable } from '@angular/core';
import packageInfo from '../../../../package.json';
import { TextConstants } from '../text-constants';
import { ApplicationConfiguration } from '../types/application-configuration.type';
import { ApiService } from './api/api.service';
import { LoggingService } from './logging.service';
import { PopupService } from './popup.service';

@Injectable({ providedIn: 'root' })
export class AppConfigurationService {
  get applicationInsightsConnectionString(): string {
    return this.appConfiguration.applicationInsightsConnectionString;
  }

  get isOnPremise(): boolean {
    return this.appConfiguration.isOnPremise;
  }

  get azureApplicationId(): string {
    return this.appConfiguration.azureApplicationId;
  }

  get azureAuthorityTenant(): string {
    return this.appConfiguration.azureAuthorityTenant;
  }

  get clientVersion(): string {
    return this.appVersion.client;
  }

  get serverVersion(): string {
    return this.appVersion.server;
  }

  private appConfiguration: ApplicationConfiguration;
  private appVersion: {
    client: string;
    server: string;
  };

  static initialize(apiService: ApiService, appConfigurationService: AppConfigurationService, loggingService: LoggingService, popupService: PopupService): () => Promise<any> {
    return async () => {
      try {
        appConfigurationService.appConfiguration = await apiService.getApplicationConfiguration();
      } catch (error) {
        await popupService.showInfo('Failed to load application configuration.', 'Server not responding', 'Try again');
        window.location.reload();
        return;
      }

      let serverVersion = TextConstants.notAvailable;
      try {
        serverVersion = await apiService.getVersion();
      } catch (error) {
        // ignored
      }

      appConfigurationService.appVersion = {
        client: packageInfo.version,
        server: serverVersion,
      };

      const appInsightsConnectionString = appConfigurationService.appConfiguration?.applicationInsightsConnectionString;
      loggingService.setupApplicationInsights(appInsightsConnectionString, serverVersion);
    };
  }
}
