import { Component, OnInit, ViewChild, ViewContainerRef } from '@angular/core';
import { MatSidenav } from '@angular/material/sidenav';
import { DeviceType } from 'src/app/shared/enums/device-type.enum';
import { PageType } from '../shared/enums/page-type.enum';
import { BreadcrumbService } from '../shared/services/breadcrumb.service';
import { SidePanelOptions, SidepanelService } from '../shared/services/sidepanel.service';
import { Global } from '../shared/types/global.type';

@Component({
  selector: 'app-layout',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.scss'],
})
export class LayoutComponent implements OnInit {
  @ViewChild(MatSidenav, { static: true }) private sidePanel: MatSidenav;
  @ViewChild('sidepanelContent', { static: true, read: ViewContainerRef }) private vcf: ViewContainerRef;

  themeLayout = 'vertical';
  layoutType = 'light';
  profileMenu = 'an-off';
  profileMenuClass: string;
  navMenuLeft = 'an-on';

  private windowWidth: number;
  readonly PageType = PageType;
  readonly DeviceType = DeviceType;

  get showBreadcrumb(): boolean {
    return this.breadcrumbService.breadcrumbItems?.length > 0;
  }

  get deviceType(): DeviceType {
    return Global.deviceType;
  }

  get sidenavSettings(): SidePanelOptions {
    return this.sidepanelService.settings;
  }

  async sidenavClose(): Promise<void> {
    await this.sidepanelService.close();
  }

  constructor(private breadcrumbService: BreadcrumbService, private sidepanelService: SidepanelService) {
    this.windowWidth = window.innerWidth;
    this.setMenuAttributes(this.windowWidth);
    this.setHeaderAttributes(this.windowWidth);
  }

  ngOnInit() {
    this.sidepanelService.setPanel(this.sidePanel);
    this.sidepanelService.setContentVcf(this.vcf);
  }

  onResize(event: UIEvent): void {
    const target = <Window>event.target;
    this.windowWidth = target.innerWidth;
    this.setHeaderAttributes(this.windowWidth);

    let reSizeFlag = true;
    if (Global.deviceType === DeviceType.tablet && this.windowWidth >= Global.mobileBreakpoint && this.windowWidth <= 992) {
      reSizeFlag = false;
    } else if (Global.deviceType === DeviceType.phone && this.windowWidth < Global.mobileBreakpoint) {
      reSizeFlag = false;
    }
    /* for check device */
    if (reSizeFlag) {
      this.setMenuAttributes(this.windowWidth);
    }
  }

  private setHeaderAttributes(windowWidth: number) {
    if (windowWidth <= Global.mobileBreakpoint) {
      this.navMenuLeft = 'an-off';
    } else {
      this.navMenuLeft = 'an-on';
    }
  }

  private setMenuAttributes(windowWidth: number) {
    // for check device type and set the device type in global variable mobile(768), tablet(992), desktop(1366)
    if (windowWidth < Global.mobileBreakpoint) {
      Global.deviceType = DeviceType.phone;
    } else if (windowWidth >= Global.mobileBreakpoint && windowWidth < Global.tabletBreakpoint) {
      Global.deviceType = DeviceType.tablet;
    } else if (windowWidth >= Global.tabletBreakpoint && windowWidth < Global.desktopBreakpoint) {
      Global.deviceType = DeviceType.desktop;
    } else {
      Global.deviceType = DeviceType.desktop;
    }
  }
}
