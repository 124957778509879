import { Component } from '@angular/core';
import { ContactUsModalComponent } from 'src/app/shared/components/_modals/contact-us-modal/contact-us-modal.component';
import { AlertType } from 'src/app/shared/enums/alert-type.enum';
import { ModalService } from 'src/app/shared/services/modal.service';
import { NavigationService } from 'src/app/shared/services/navigation.service';
import { NotificationsService } from 'src/app/shared/services/notifications.service';

@Component({
  selector: 'app-alert-bar',
  templateUrl: './alert-bar.component.html',
  styleUrls: ['./alert-bar.component.scss'],
})
export class AlertBarComponent {
  readonly AlertType = AlertType;
  constructor(public notificationService: NotificationsService, private modalService: ModalService, private navigationService: NavigationService) {}

  close(event: any): void {
    let parent = event.parentElement;
    parent.style.display = 'none';
  }

  openContactSupportModal(): void {
    this.modalService.open(ContactUsModalComponent);
  }

  navigateByRoute(route: string[]) {
    this.navigationService.navigateByRoute(route || ['']);
  }
}
