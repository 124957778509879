import { ScrollingModule } from '@angular/cdk/scrolling';
import { registerLocaleData } from '@angular/common';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import localeCs from '@angular/common/locales/cs';
import localeCsExtra from '@angular/common/locales/extra/cs';
import { NgModule, LOCALE_ID, APP_INITIALIZER, ErrorHandler } from '@angular/core';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { MAT_RIPPLE_GLOBAL_OPTIONS, RippleGlobalOptions } from '@angular/material/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouteReuseStrategy } from '@angular/router';
import { SvgIconsModule } from '@ngneat/svg-icon';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { PageNotFoundComponent } from './layout/_global-errors/page-not-found/page-not-found.component';
import { ServerErrorComponent } from './layout/_global-errors/server-error/server-error.component';
import { AlertBarComponent } from './layout/alert-bar/alert-bar.component';
import { LayoutComponent } from './layout/layout.component';
import { LoadingScreenComponent } from './layout/loading-screen/loading-screen.component';
import { MenuComponent } from './layout/menu/menu.component';
import { OrganizationSelectComponent } from './layout/organization-select/organization-select.component';
import { SidePanelComponent } from './layout/side-panel/side-panel.component';
import { JumpToModalComponent } from './shared/components/_modals/jump-to-modal/jump-to-modal.component';
import { NotificationSettingsModalComponent } from './shared/components/_modals/notification-settings-modal/notification-settings-modal.component';
import { CALENDAR_CONFIG, CalendarConfig } from './shared/components/calendar/calendar-interval-dropdown/calendar-interval-dropdown.component';
import { GlobalErrorHandler } from './shared/handlers/global-error.handler';
import { DateHelper } from './shared/helpers/date.helper';
import { FormatHelper } from './shared/helpers/format.helper';
import { HttpRequestInterceptor } from './shared/interceptors/http.request.interceptor';
import { ApiService } from './shared/services/api/api.service';
import { AppConfigurationService } from './shared/services/app-configuration.service';
import { LoggingService } from './shared/services/logging.service';
import { PopupService } from './shared/services/popup.service';
import { SharedModule } from './shared/shared.module';
import { CustomRouteReuseStrategy } from './shared/types/custom-route-reuse-strategy.type';
import { Data } from './shared/types/data.type';
import { Global } from './shared/types/global.type';
import { sharedIcons } from './svg/shared';
import { navigationIcons } from './svg/navigation';

registerLocaleData(localeCs, 'cs', localeCsExtra);
const globalRippleConfig: RippleGlobalOptions = {
  disabled: true,
  animation: {
    enterDuration: 0,
    exitDuration: 0,
  },
};

async function unregisterServiceWorker(): Promise<void> {
  try {
    if (navigator?.serviceWorker != null) {
      const registrations = await navigator.serviceWorker.getRegistrations();
      registrations.forEach(async r => {
        const result = await r.unregister();
        console.log('Service worker unregistered with result', result);
      });
    }

    if (window.caches != null) {
      const keys = await window.caches.keys();
      await Promise.all(keys.map(k => window.caches.delete(k)));
    }
  } catch (error) {
    console.error('Service worker could not be unregistered', error);
  }
}

@NgModule({
  declarations: [
    AppComponent,
    LayoutComponent,
    LoadingScreenComponent,
    PageNotFoundComponent,
    ServerErrorComponent,
    JumpToModalComponent,
    SidePanelComponent,
    MenuComponent,
    OrganizationSelectComponent,
    AlertBarComponent,
    NotificationSettingsModalComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    SharedModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    ScrollingModule,
    SvgIconsModule.forRoot({
      defaultSize: 'md',
      sizes: {
        xs: '10px',
        sm: '14px',
        md: '16px',
        lg: '24px',
        xl: '32px',
        xxl: '42px',
      },
    }),
    SvgIconsModule.forChild(sharedIcons),
    SvgIconsModule.forChild(navigationIcons),
  ],
  schemas: [],
  providers: [
    { provide: MAT_RIPPLE_GLOBAL_OPTIONS, useValue: globalRippleConfig },
    LoggingService,
    Data,
    { provide: ErrorHandler, useClass: GlobalErrorHandler },
    { provide: HTTP_INTERCEPTORS, useClass: HttpRequestInterceptor, multi: true },
    { provide: APP_INITIALIZER, useFactory: AppConfigurationService.initialize, deps: [ApiService, AppConfigurationService, LoggingService, PopupService], multi: true },
    { provide: APP_INITIALIZER, useFactory: Global.initialize, deps: [LOCALE_ID], multi: true },
    { provide: APP_INITIALIZER, useFactory: FormatHelper.initializeLocale, deps: [LOCALE_ID], multi: true },
    { provide: APP_INITIALIZER, useFactory: DateHelper.initializeLocale, deps: [LOCALE_ID], multi: true },
    { provide: APP_INITIALIZER, useFactory: () => () => unregisterServiceWorker(), multi: true },
    { provide: CALENDAR_CONFIG, useFactory: (appConfig: AppConfigurationService) => ({ show30DaysButton: !appConfig.isOnPremise } as CalendarConfig), deps: [AppConfigurationService] },
    { provide: RouteReuseStrategy, useClass: CustomRouteReuseStrategy },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
