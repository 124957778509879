import { Directive, HostListener, Input } from '@angular/core';
import { TooltipDirective } from '@looorent/ng2-tooltip-directive';

@Directive({
  selector: '[tooltipDisplayTarget]',
})
export class TooltipDisplayTargetDirective {
  @Input('tooltipDisplayTarget') tooltipDisplayTargetId: string;

  constructor(private tooltipDirective: TooltipDirective) {}

  @HostListener('mouseenter') onMouseEnter(): void {
    if (!this.tooltipDisplayTargetId) {
      return;
    }

    const el = document.getElementById(this.tooltipDisplayTargetId);
    const shouldDisplay = el ? el.offsetWidth < el.scrollWidth : false;
    this.tooltipDirective.display = shouldDisplay;
    if (shouldDisplay) {
      this.tooltipDirective.show();
    } else {
      this.tooltipDirective.hide();
    }
  }
}
