import { Directive, HostListener } from '@angular/core';
import { TooltipDirective } from '@looorent/ng2-tooltip-directive';

@Directive({
  selector: '[tooltipHideOnClick]',
})
export class TooltipHideOnClickDirective {
  constructor(private tooltip: TooltipDirective) {}

  // update this to prevent default click event from firing
  @HostListener('click', ['$event'])
  onClick(event: MouseEvent): void {
    this.tooltip.options = { ...this.tooltip.options, hideDelay: 0 };
    setTimeout(() => {
      this.tooltip.destroyTooltip({ fast: true });
    }, 0);
  }
}
